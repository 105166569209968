import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, mergeMap, Observable, of, switchMap, toArray } from 'rxjs';

import { isAdmin } from '../../../../../shared/auth/auth-utils';
import { LayoutService } from '../../../../../shared/services/layout.service';
import { ProjectProfileDto } from '../../../projects/api/project-profile.model';
import { ProjectProfileService } from '../../../projects/api/project-profile.service';
import { UserMeDto } from '../../../shared/api/user-details.model';
import { UserDetailsService } from '../../../shared/api/user-details.service';
import { ProjectSpokeSimpleDto } from '../../api/project-spoke-simple.model';
import { ProjectSpokesService } from '../../api/services/project-spokes.service';
import { ProjectSpokesDataComponent } from '../project-spokes-data/project-spokes-data.component';
import { SpokeRequestCreateComponent } from '../spoke-request-create/spoke-request-create.component';

@Component({
  selector: 'app-project-spokes-list',
  templateUrl: './project-spokes-list.component.html',
  providers: [ DialogService ],
})
export class ProjectSpokesListComponent {

  // a value defining if this view is drawn in context of admin or standard user
  isAdmin: boolean = false;

  filters: ProjectSpokesFilters;
  filterProjects: string[] = [];
  filterStatuses: string[] = [];
  filterTenants: string[] = [];
  filterEnvironments: string[] = [];

  userProjects: ProjectProfileDto[] = [];
  projectSpokes: ProjectSpokeSimpleDto[] = [];
  projectSpokesFiltered: ProjectSpokeSimpleDto[] = [];

  hubDetailsDialog?: DynamicDialogRef;
  spokeRequestCreateNewDialog?: DynamicDialogRef;

  constructor(
    private projectSpokesService: ProjectSpokesService,
    private projectsService: ProjectProfileService,
    private userService: UserDetailsService,
    private dialogService: DialogService,
    private layout: LayoutService,
  ) {
    this.filters = new ProjectSpokesFilters();
   }

  async ngOnInit() {
    await this.refreshList();
  }

  async refreshList() {
    this.layout.startLoading();

    const projectSpokes$ = this.userService.userMeGet()
      .pipe(
        switchMap(user => {
          if (isAdmin(user)) {
            this.isAdmin = true;
            return this.getSpokesForAllProjects();
          }
          else {
            this.isAdmin = false;
            return this.getSpokesForUserProjects(user);
          }
        })
      );

    projectSpokes$
    .subscribe({
      next: (response) => {
        this.projectSpokes = response;
        this.refreshFilterLists(response);
        this.filterList();
        this.layout.stopLoading();
      },
      error: (error) => {
        this.layout.stopLoading();
      }
    });
  }

  getSpokesForUserProjects(user: UserMeDto): Observable<ProjectSpokeSimpleDto[]> {
    return of(Object.keys(user.projects))
    .pipe(
      switchMap((projects) =>
        of(...projects).pipe(
          mergeMap(project => this.projectSpokesService.projectSpokesGetListByProject(project)),
          toArray(),
          map(projectLists => ([] as ProjectSpokeSimpleDto[]).concat(...projectLists)),
        ))
    )
  }

  getSpokesForAllProjects(): Observable<ProjectSpokeSimpleDto[]> {
    return this.projectsService.projectProfiles()
    .pipe(
      switchMap((projects) =>
        of(...projects).pipe(
          mergeMap(project => this.projectSpokesService.projectSpokesGetListByProject(project.projectId)),
          toArray(),
          map(projectLists => ([] as ProjectSpokeSimpleDto[]).concat(...projectLists)),
        ))
    )
  }

  refreshFilterLists(tableData: ProjectSpokeSimpleDto[]) {
    this.filterProjects = [...new Set(tableData.map(q => q.projectId).filter((q): q is string => q !== undefined || q !== null))];
    this.filterStatuses = [...new Set(tableData.map(q => q.status).filter((q): q is string => q !== undefined || q !== null))];
    this.filterTenants = [...new Set(tableData.map(q => q.tenantName).filter((q): q is string => q !== undefined || q !== null))];
    this.filterEnvironments = [...new Set(tableData.map(q => q.environment).filter((q): q is string => q !== undefined || q !== null))];
  }

  filterList() {
    this.projectSpokesFiltered = this.projectSpokes.filter(q => {
      return (!this.filters.tenant || q.tenantName === this.filters.tenant) &&
              (!this.filters.status || q.status === this.filters.status) &&
              (!this.filters.project || q.projectId === this.filters.project) &&
              (!this.filters.environment || q.environment === this.filters.environment);
    });
  }

  filtersClear() {
    this.filters.project = undefined;
    this.filters.status = undefined;
    this.filters.tenant = undefined;
    this.filters.environment = undefined;

    this.filterList();
  }

  openProjectSpokeDetailsDialog(projectSpoke?: ProjectSpokeSimpleDto) {
    this.hubDetailsDialog = this.dialogService
      .open(ProjectSpokesDataComponent, {
        data: {
          projectId: projectSpoke?.projectId,
          spokeId: projectSpoke?.spokeId,
        },
        header: 'Project Spokes - Spoke Data',
        width: '800px',
        contentStyle: {"min-height": "800px", "background": "var(--surface-ground)"},
      });
  }

  openSpokeRequestCreateDialog() {
    this.spokeRequestCreateNewDialog = this.dialogService
      .open(SpokeRequestCreateComponent, {
        header: 'Spoke Request - Create New',
        width: '800px',
        contentStyle: {"min-height": "800px", "padding": "5%"},
      });

    this.spokeRequestCreateNewDialog.onClose
      .subscribe(() => {
          this.refreshList();
      });
  }
}

class ProjectSpokesFilters {
  project?: string;
  status?: string;
  tenant?: string;
  environment?: string;
}
